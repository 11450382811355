<template>
  <div>
    <input ref="fileInput" style="display: none" type="file" @change="onFileSelected">
    <div v-if="video && !withoutPreview" class="video-preview">
      <video class="preview" controls>
        <source :src="video.path ? video.path : previewUrl" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>
    <div v-if="!video && previewUrl && !withoutPreview" class="video-preview">
      <video :src="previewUrl" class="preview"/>
    </div>
    <button class="btn btn-outline mt-3" size="sm" @click="$refs.fileInput.click()">
      <span class="text-dark">{{ file ? textChange : textUpload }}</span>
    </button>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
      required: false
    },
    withoutPreview: {
      type: Boolean,
      default: false
    },
    withoutUpload: {
      type: Boolean,
      default: false
    },
    textChange: {
      type: String,
      default: 'Замените видео'
    },
    textUpload: {
      type: String,
      default: 'Загрузите видео'
    }
  },
  computed: {
    video: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  data() {
    return {
      file: null,
      previewUrl: null,
      show: !this.video,
    }
  },
  methods: {
    onFileSelected(event) {
      this.file = event.target.files[0];
      this.previewUrl = URL.createObjectURL(this.file);

      if (this.withoutUpload) {
        this.video.file = this.file;
      } else {
        this.saveVideo();
      }
    },
    async saveVideo() {
      try {
        let formData = new FormData();
        formData.append('video', this.file);
        await this.$store.dispatch('CREATE_VIDEO', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.$notify({
          type: "success",
          message: 'Видео успешно загружено'
        });
        this.video = this.$store.getters["VIDEO"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Уупс, что-то пошло не так!",
        });
      }
    }
  }
}
</script>
<style scoped>
video.preview {
  width: 200px;
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
}
</style>