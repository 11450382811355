<template>
  <div class="container-fluid mt--7">
    <div class="order-xl-1 mb-5 mb-xl-0">
      <card v-if="customPage" shadow type="secondary">
        <template v-slot:header>
          <div class="bg-white border-0">
            <div class="row align-items-center">
              <div class="col text-dark">
                <h3 class="mb-0">Office of Knowledge and Innovation</h3>
              </div>
              <div class="col text-right">
                <button class="btn btn-outline" size="sm" @click="rollback">
                  <i class="fas fa-lg fa-redo text-dark"></i>
                </button>
                <button class="btn btn-outline" size="sm" @click="handleSubmit">
                  <i class="fas fa-lg fa-save text-dark"></i>
                </button>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:default>
          <div class="row">
            <div class="col-8">
              <h6 class="heading-small text-muted">Основная информация</h6>
              <tabs>
                <tab-pane v-bind:key="'mainInfoRU'" title="RU">
                  <base-input
                      v-model="customPage.title_ru"
                      input-classes="form-control-alternative"
                      label="Заголовок"
                      placeholder="Введите заголовок"
                  />
                  <label class="form-control-label">Head</label>
                  <editor v-model="customPage.head_text_ru" api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative "/>
                  <label class="form-control-label mt-4">About</label>
                  <editor v-model="customPage.about_text_ru" api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative"/>
                </tab-pane>
                <tab-pane v-bind:key="'mainInfoEN'" title="EN">
                  <base-input
                      v-model="customPage.title_en"
                      input-classes="form-control-alternative"
                      label="Заголовок"
                      placeholder="Введите заголовок"
                  />
                  <label class="form-control-label">Head</label>
                  <editor v-model="customPage.head_text_en" api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative"/>
                  <label class="form-control-label">About</label>
                  <editor v-model="customPage.about_text_en" api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative"/>
                </tab-pane>
                <tab-pane v-bind:key="'mainInfoAR'" title="AR">
                  <base-input
                      v-model="customPage.title_ar"
                      input-classes="form-control-alternative"
                      label="Заголовок"
                      placeholder="Введите заголовок"
                  />
                  <label class="form-control-label">Head</label>
                  <editor v-model="customPage.head_text_ar" api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative"/>
                  <label class="form-control-label">About</label>
                  <editor v-model="customPage.about_text_ar" api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative"/>
                </tab-pane>
              </tabs>
            </div>
            <div class="col-4">
              <div class="form-group text-center">
                <UploadVideo v-model="customPage.video"/>
              </div>
              <hr class="my-4"/>

              <div class="form-group text-center">
                <UploadVideo
                    v-model="customPage.mobile_video"
                    textChange="Изменить видео для мобильной версии"
                    textUpload="Загрузить видео для мобильной версии"/>
              </div>
              <hr class="my-4"/>

              <label class="form-control-label mt-4">Показывать видео</label>
              <div class="form-group">
                <label class="custom-toggle">
                  <input v-model="customPage.show_video"
                         type="checkbox">
                  <span class="custom-toggle-slider rounded-circle"></span>
                </label>
              </div>

              <hr class="my-4"/>

              <div class="form-group text-center">
                <UploadImage
                    v-model="customPage.image"
                    textChange="Изменить изображение"
                    textUpload="Загрузить изображение"
                />
              </div>

              <hr class="my-4"/>

              <div class="form-group text-center">
                <UploadImage
                    v-model="customPage.mobile_image"
                    textChange="Изменить изображение для мобильной версии"
                    textUpload="Загрузить изображение для мобильной версии"
                />
              </div>
            </div>
          </div>
          <h6 class="heading-small text-muted mb-4">{{
              customPage.persons.length > 0 ? 'OUR TEAM' : 'Похоже, что в команде никого( Нажмите на плюсик ниже чтобы добавить'
            }}</h6>
          <OurTeam v-model="customPage.persons" @delete="deletePerson"/>
        </template>
        <template v-slot:footer>
          <base-button size="sm" type="btn-outline" @click="addNewPerson"><i
              class="fas fa-lg fa-plus text-dark"></i></base-button>
        </template>
      </card>
    </div>
  </div>
</template>

<script>

import Editor from "@tinymce/tinymce-vue";
import UploadImage from "../../components/UploadImage";
import UploadVideo from "../../components/UploadVideo";
import OurTeam from "../../components/OurTeam";

export default {
  name: "form",
  components: {
    OurTeam, UploadImage, UploadVideo, Editor
  },
  data() {
    return {
      locales: null,
      customPage: null,
    }
  },

  async created() {
    this.$store.watch(
        () => this.$store.getters["LOCALES"],
        (list) => {
          this.locales = list;
        }
    );
    await this.$store.dispatch("GET_LOCALES_LIST");

    this.$store.watch(
        () => this.$store.getters["OKI_PAGE"],
        (page) => {
          this.customPage = page;
        }
    );
    await this.$store.dispatch("GET_OKI_PAGE");
  },

  methods: {
    async handleSubmit() {
      try {
        await this.$store.dispatch("UPDATE_OKI_PAGE", this.customPage)
        this.$notify({
          type: "info",
          message: "Информация обновлена",
        });
      } catch (error) {

        this.$notify({
          type: "danger",
          message: "Уупс, что-то пошло не так!",
        });
      }
    },
    async rollback() {
      await this.$store.dispatch("GET_OKI_PAGE");
    },
    addNewPerson() {
      this.customPage.persons.push({
        position: null,
        first_name_ru: null,
        first_name_en: null,
        first_name_ar: null,
        second_name_ru: null,
        second_name_en: null,
        second_name_ar: null,
        bio_ru: null,
        bio_en: null,
        bio_ar: null,
        job_ru: null,
        job_en: null,
        job_ar: null,
        locales: this.locales,
        image: null,
        preview: null,
      });
    },
    deletePerson(person) {
      this.customPage.persons = this.customPage.persons.filter(item => item.id !== person.id);
    }
  }
}
</script>
<style lang="scss">
.person-trash-button {
  bottom: 0;
}
</style>