<template>
  <div class="container-fluid mt--7">
    <div class="order-xl-1 mb-5 mb-xl-0">
      <card v-if="page && locales" shadow type="secondary">
        <template v-slot:header>
          <div class="bg-white border-0">
            <div class="row align-items-center">
              <div class="col text-dark">
                <h3 v-if="isCreating" class="mb-0">Создание страницы</h3>
                <h3 v-else class="mb-0">Редактирование страницы</h3>
              </div>
              <div class="col text-right">
                <button v-if="isCreating === false" class="btn btn-outline" size="sm" @click="rollback">
                  <i class="fas fa-lg fa-redo text-dark"></i>
                </button>
                <button class="btn btn-outline" size="sm" @click="handleSubmit">
                  <i class="fas fa-lg fa-save text-dark"></i>
                </button>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:default>
          <div class="row">
            <div class="col-8">
              <h6 class="heading-small text-muted mb-4">Основная информация</h6>
              <tabs>
                <tab-pane v-bind:key="'mainInfoRU'" title="RU">
                  <base-input
                      v-model="page.title_ru"
                      input-classes="form-control-alternative"
                      label="Заголовок"
                      placeholder="Введите заголовок"
                  />
                  <label class="form-control-label">Превью текст</label>
                  <editor v-model="page.preview_text_ru" api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative "/>

                  <label class="form-control-label mt-4">Текст</label>
                  <editor v-model="page.text_ru" api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative "/>

                  <hr class="my-4"/>

                  <h6 class="heading-small text-muted mb-4">Поля расширенной версии</h6>

                  <base-input
                      v-model="page.link_ru"
                      input-classes="form-control-alternative"
                      label="Ссылка"
                      placeholder="введите ссылку"
                  />

                  <base-input
                      v-model="page.middle_text_title_ru"
                      class="mt-4"
                      input-classes="form-control-alternative"
                      label="Заголовок дополнительного блока текста"
                      placeholder="Введите заголовок"
                  />
                  <label class="form-control-label">Дополнительный блок текста</label>
                  <editor v-model="page.middle_text_ru"
                          api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative"/>

                  <base-input
                      v-model="page.left_text_title_ru"
                      class="mt-4"
                      input-classes="form-control-alternative"
                      label="Заголовок дополнительного блока текста слева"
                      placeholder="Введите заголовок"
                  />
                  <label class="form-control-label mb-4">Дополнительный блок текста слева</label>
                  <editor v-model="page.left_text_ru"
                          api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternativemb-4"/>

                  <base-input
                      v-model="page.right_text_title_ru"
                      class="mt-4"
                      input-classes="form-control-alternative"
                      label="Заголовок дополнительного блока справа"
                      placeholder="Введите заголовок"
                  />
                  <label class="form-control-label">Дополнительный блок текста справа</label>
                  <editor v-model="page.right_text_ru"
                          api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative"/>
                </tab-pane>
                <tab-pane v-bind:key="'mainInfoEN'" title="EN">
                  <base-input
                      v-model="page.title_en"
                      input-classes="form-control-alternative"
                      label="Заголовок"
                      placeholder="Введите заголовок"
                  />

                  <label class="form-control-label">Превью текст</label>
                  <editor v-model="page.preview_text_en" api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative "/>

                  <label class="form-control-label mt-4">Текст</label>
                  <editor v-model="page.text_en" api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative "/>

                  <hr class="my-4"/>

                  <h6 class="heading-small text-muted mb-4">Поля расширенной версии</h6>

                  <base-input
                      v-model="page.link_en"
                      input-classes="form-control-alternative"
                      label="Ссылка"
                      placeholder="введите ссылку"
                  />

                  <base-input
                      v-model="page.middle_text_title_en"
                      class="mt-4"
                      input-classes="form-control-alternative"
                      label="Заголовок дополнительного блока текста"
                      placeholder="Введите заголовок"
                  />
                  <label class="form-control-label">Дополнительный блок текста</label>
                  <editor v-model="page.middle_text_en"
                          api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative"/>

                  <base-input
                      v-model="page.left_text_title_en"
                      class="mt-4"
                      input-classes="form-control-alternative"
                      label="Заголовок дополнительного блока текста слева"
                      placeholder="Введите заголовок"
                  />
                  <label class="form-control-label">Дополнительный блок текста слева</label>
                  <editor v-model="page.left_text_en"
                          api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative"/>

                  <base-input
                      v-model="page.right_text_title_en"
                      class="mt-4"
                      input-classes="form-control-alternative"
                      label="Заголовок дополнительного блока справа"
                      placeholder="Введите заголовок"
                  />
                  <label class="form-control-label">Дополнительный блок текста справа</label>
                  <editor v-model="page.right_text_en"
                          api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative"/>
                </tab-pane>
                <tab-pane v-bind:key="'mainInfoAR'" title="AR">
                  <base-input
                      v-model="page.title_ar"
                      input-classes="form-control-alternative"
                      label="Заголовок"
                      placeholder="Введите заголовок"
                  />

                  <label class="form-control-label">Превью текст</label>
                  <editor v-model="page.preview_text_ar" api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative "/>

                  <label class="form-control-label mt-4">Текст</label>
                  <editor v-model="page.text_ar" api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative "/>

                  <hr class="my-4"/>

                  <h6 class="heading-small text-muted mb-4">Поля расширенной версии</h6>

                  <base-input
                      v-model="page.link_ar"
                      input-classes="form-control-alternative"
                      label="Ссылка"
                      placeholder="введите ссылку"
                  />

                  <base-input
                      v-model="page.middle_text_title_ar"
                      class="mt-4"
                      input-classes="form-control-alternative"
                      label="Заголовок дополнительного блока текста"
                      placeholder="Введите заголовок"
                  />
                  <label class="form-control-label">Дополнительный блок текста</label>
                  <editor v-model="page.middle_text_ar"
                          api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative"/>

                  <base-input
                      v-model="page.left_text_title_ar"
                      class="mt-4"
                      input-classes="form-control-alternative"
                      label="Заголовок дополнительного блока текста слева"
                      placeholder="Введите заголовок"
                  />
                  <label class="form-control-label">Дополнительный блок текста слева</label>
                  <editor v-model="page.left_text_ar"
                          api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative"/>

                  <base-input
                      v-model="page.right_text_title_ar"
                      class="mt-4"
                      input-classes="form-control-alternative"
                      label="Заголовок дополнительного блока справа"
                      placeholder="Введите заголовок"
                  />
                  <label class="form-control-label">Дополнительный блок текста справа</label>
                  <editor v-model="page.right_text_ar"
                          api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                          class="form-control-alternative"/>
                </tab-pane>
              </tabs>
            </div>
            <div class="col-4">
              <div class="form-group text-center">
                <label class="form-control-label">Выберите дату публикации страницы</label>
                <datetime v-model="page.published_at" type="datetime"></datetime>
              </div>
              <hr class="my-4"/>

              <div class="form-group text-center">
                <base-input
                    v-model="page.position"
                    input-classes="form-control-alternative"
                    label="Позиция"
                    placeholder="Позиция"
                    type="number"
                />
              </div>
              <hr class="my-4"/>

              <div class="form-group text-center">
                <UploadImage
                    v-model="page.preview"
                    textChange="Изменить превью"
                    textUpload="Загрузить превью"
                />
              </div>

              <hr class="my-4"/>

              <div class="form-group text-center">
                <UploadImage
                    v-model="page.image"
                    textChange="Изменить основное изображение"
                    textUpload="Загрузить основное изображение"
                />
              </div>

              <hr class="my-4"/>

              <div class="form-group text-center">
                <UploadImage
                    v-model="page.mobile_image"
                    textChange="Изменить изображение для мобильной версии"
                    textUpload="Загрузить изображение для мобильной версии"/>
              </div>
            </div>
          </div>
        </template>
      </card>
    </div>
  </div>
</template>

<script>

import Editor from "@tinymce/tinymce-vue";
import UploadImage from "../../components/UploadImage";
import {Datetime} from 'vue-datetime';

export default {
  name: "PageForm",
  components: {Editor, UploadImage, Datetime},
  computed: {
    isCreating() {
      return this.$route.params.id === undefined;
    }
  },

  data() {
    return {
      page: null,
      locales: null,
      second_layout: false,
    }
  },

  async created() {
    this.$store.watch(
        () => this.$store.getters["PAGE"],
        (page) => {
          this.page = page;
          if (this.page.left_text_ru || this.page.left_text_en || this.page.left_text_ar) {
            this.second_layout = true
          }
        }
    );

    this.$store.watch(
        () => this.$store.getters["LOCALES"],
        (list) => {
          this.locales = list;
        }
    );

    await this.$store.dispatch("GET_LOCALES_LIST");

    if (this.$route.params.id) {
      await this.$store.dispatch("GET_PAGE", {id: this.$route.params.id});
    } else {
      await this.$store.commit("SET_PAGE", {
        position: null,
        title_ru: null,
        title_en: null,
        title_ar: null,
        preview_text_ru: null,
        preview_text_en: null,
        preview_text_ar: null,
        text_ru: null,
        text_en: null,
        text_ar: null,
        middle_text_ru: null,
        middle_text_en: null,
        middle_text_ar: null,
        left_text_ru: null,
        left_text_en: null,
        left_text_ar: null,
        right_text_ru: null,
        right_text_en: null,
        right_text_ar: null,
        middle_text_title_ru: null,
        middle_text_title_en: null,
        middle_text_title_ar: null,
        left_text_title_ru: null,
        left_text_title_en: null,
        left_text_title_ar: null,
        right_text_title_ru: null,
        right_text_title_en: null,
        right_text_title_ar: null,
        link_ru: null,
        link_en: null,
        link_ar: null,
        image: null,
        mobile_image: null,
        preview: null,
        locales: this.locales.filter(locale => locale.slug === 'ru')
      })
    }
  },

  methods: {
    checkIfLandIsPresent(slug) {
      this.selectedLanguage.find(function (ele) {
        return ele.value === slug;
      });
      return false;
    },

    async handleSubmit() {
      try {

        let page = this.page;

        if (!this.empty(page.title_ru) || !this.empty(page.text_ru) || !this.empty(page.middle_text_ru) || !this.empty(page.left_text_ru) || !this.empty(page.right_text_ru)) {
          if (!page.locales.find(locale => locale.slug === 'ru')) {
            page.locales.push(this.locales.filter(locale => locale.slug === 'ru')[0])
          }
        } else {
          page.locales = page.locales.filter(locale => locale.slug !== 'ru');
        }

        if (!this.empty(page.title_en) || !this.empty(page.text_en) || !this.empty(page.middle_text_en) || !this.empty(page.left_text_en) || !this.empty(page.right_text_en)) {
          if (!page.locales.find(locale => locale.slug === 'en')) {
            page.locales.push(this.locales.filter(locale => locale.slug === 'en')[0])
          }
        } else {
          page.locales = page.locales.filter(locale => locale.slug !== 'en');
        }

        if (!this.empty(page.title_ar) || !this.empty(page.text_ar) || !this.empty(page.middle_text_ar) || !this.empty(page.left_text_ar) || !this.empty(page.right_text_ar)) {
          if (!page.locales.find(locale => locale.slug === 'ar')) {
            page.locales.push(this.locales.filter(locale => locale.slug === 'ar')[0])
          }
        } else {
          page.locales = page.locales.filter(locale => locale.slug !== 'ar');
        }

        if (this.isCreating) {
          await this.$store.dispatch("CREATE_PAGE", page);
        } else {
          await this.$store.dispatch("UPDATE_PAGE", page);
        }

        this.$notify({
          type: "success",
          message: this.isCreating ? 'Страница успешно создана' : ' Страница успешно обновлена',
        });
        if (this.isCreating) {
          await this.$router.push({name: "oki.pages.show", params: {id: this.page.id}});
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Уупс, что-то пошло не так!",
        });
      }
    },

    async rollback() {
      await this.$store.dispatch("GET_PAGE", {id: this.$route.params.id});
    },

    async deleteArticle() {
      await this.$store.dispatch("DELETE_PAGE", {
        id: this.$route.params.id
      }).then(() => {
        this.$notify({type: 'success', message: 'Страница успешно удалена'})
        this.$router.push({name: "news"});
      }).catch(() => {
        this.$notify({type: 'danger', message: 'Страница не удалена'})
      });
    },
    empty(data) {
      if (typeof data === "undefined") {
        return true;
      } else if (data === "0") {
        return true;
      } else if (!data) {
        return true;
      } else if (/\S/.test(data) == "") {
        return true;
      } else {
        return false;
      }
    }
  },
}
</script>
