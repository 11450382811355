<template>
  <div class="container-fluid mt--7">
    <div class="row">
      <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
        <card shadow type="secondary">
          <template v-slot:default>
            <div class="form-group text-center">
              <label class="form-control-label">Выберите дату публикации новости</label>
              <datetime v-model="article.published_at" type="datetime"></datetime>
            </div>
            <hr class="my-4"/>

            <div v-if="similarNewsList && similarNewsList.length > 0" class="form-group text-center">
              <label class="form-control-label">Выберите похожие новости</label>
              <Multiselect
                  v-model="selectedSimilarNews"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :max="6"
                  :multiple="true"
                  :options="similarNewsList"
                  label="label"
                  track-by="value"
              />
            </div>
            <hr class="my-4"/>

            <div v-if="galleriesList && galleriesList.length > 0" class="form-group text-center">
              <label class="form-control-label">Выберите галерею</label>
              <Multiselect
                  v-model="selectedGallery"
                  :options="galleriesList"
                  label="label"
                  mode="single"
                  track-by="value"
              />
            </div>
            <hr class="my-4"/>

            <div class="form-group text-center">
              <UploadImage
                  v-model="article.preview"
                  textChange="Изменить превью"
                  textUpload="Загрузить превью"
              />
            </div>

            <hr class="my-4"/>

            <div class="form-group text-center">
              <UploadImage
                  v-model="article.image"
                  textChange="Изменить основное изображение"
                  textUpload="Загрузить основное изображение"
              />
            </div>
          </template>
        </card>
      </div>
      <div class="col-xl-8 mb-5 mb-xl-0">
        <card shadow type="secondary">
          <template v-slot:header>
            <div class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col text-dark">
                  <h3 v-if="isCreating" class="mb-0">Создание страницы</h3>
                  <h3 v-else class="mb-0">Редактирование страницы</h3>
                </div>
                <div class="col text-right">
                  <button v-if="isCreating === false" class="btn btn-outline" size="sm" @click="rollback">
                    <i class="fas fa-lg fa-redo text-dark"></i>
                  </button>
                  <button class="btn btn-outline" size="sm" @click="handleSubmit">
                    <i class="fas fa-lg fa-save text-dark"></i>
                  </button>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:default>
            <h6 class="heading-small text-muted mb-4">Основная информация</h6>
            <tabs>
              <tab-pane v-bind:key="'mainInfoRU'" title="RU">
                <base-input
                    v-model="article.title_ru"
                    input-classes="form-control-alternative"
                    label="Заголовок"
                    placeholder="Введите заголовок"
                />
                <label class="form-control-label">Текст</label>
                <editor v-model="article.body_ru" api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                        class="form-control-alternative "/>

                <label class="form-control-label">Краткое описание</label>
                <editor v-model="article.desc_ru"
                        api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                        class="form-control-alternative"/>
              </tab-pane>
              <tab-pane v-bind:key="'mainInfoEN'" title="EN">
                <base-input
                    v-model="article.title_en"
                    input-classes="form-control-alternative"
                    label="Заголовок"
                    placeholder="Введите заголовок"
                />
                <label class="form-control-label">Текст</label>
                <editor v-model="article.body_en" api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                        class="form-control-alternative "/>

                <label class="form-control-label">Краткое описание</label>
                <editor v-model="article.desc_en"
                        api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                        class="form-control-alternative"/>
              </tab-pane>
              <tab-pane v-bind:key="'mainInfoAR'" title="AR">
                <base-input
                    v-model="article.title_ar"
                    input-classes="form-control-alternative"
                    label="Заголовок"
                    placeholder="Введите заголовок"
                />
                <label class="form-control-label">Текст</label>
                <editor v-model="article.body_ar" api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                        class="form-control-alternative "/>

                <label class="form-control-label">Краткое описание</label>
                <editor v-model="article.desc_ar"
                        api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                        class="form-control-alternative"/>
              </tab-pane>
            </tabs>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>

<script>

import Multiselect from "vue-multiselect";
import Editor from "@tinymce/tinymce-vue";
import UploadImage from "../../components/UploadImage";
import {Datetime} from 'vue-datetime';

export default {
  name: "NewsForm",
  components: {Editor, Multiselect, UploadImage, Datetime},
  computed: {
    isCreating() {
      return this.$route.params.id === undefined;
    },
    article: {
      get() {
        return this.$store.getters["ARTICLE"];
      },
      set(value) {
        this.$store.commit("SET_ARTICLE", value);
      }
    }
  },

  data() {
    return {
      locales: null,
      selectedSimilarNews: [],
      similarNewsList: null,

      selectedGallery: null,
      galleriesList: [],
    }
  },

  async created() {

    this.$store.watch(
        () => this.$store.getters["LOCALES"],
        (list) => {
          this.locales = list;
        }
    );
    await this.$store.dispatch("GET_LOCALES_LIST");

    this.$store.watch(
        () => this.$store.getters["RELATED_NEWS_LIST"],
        (list) => {
          this.similarNewsList = list;
        }
    );
    await this.$store.dispatch("GET_RELATED_NEWS_LIST");

    this.$store.watch(
        () => this.$store.getters["ARTICLE"],
        (article) => {
          this.article = article;
          this.selectedLanguage = article.locale;

          if (this.galleriesList.length > 0) {
            this.galleriesList.forEach(gallery => {
              if (gallery.value === this.article.gallery_id) {
                this.selectedGallery = gallery;
              }
            });
          }
          let relatedNewsIds = [];
          if ("related" in article && article.related !== null && article.related.length > 0) {
            article.related.forEach(item => {
              relatedNewsIds.push(item);
            });
            this.selectedSimilarNews = relatedNewsIds;
          }
        }
    );

    this.$store.watch(
        () => this.$store.getters["GALLERIES_LIST"],
        (list) => {

          let processedList = [];

          list.forEach(item => {
            if (item.id === this.article.gallery_id) {
              this.selectedGallery = {
                value: item.id,
                label: item.name
              }
            }

            processedList.push({
              value: item.id,
              label: item.name
            });
          });

          this.galleriesList = processedList;
        }
    );
    await this.$store.dispatch("GET_GALLERIES_LIST");

    if (this.$route.params.id) {
      await this.$store.dispatch("GET_ARTICLE", {id: this.$route.params.id});
    } else {
      await this.$store.commit("SET_ARTICLE", {
        id: null,
        locales: this.locales.filter(locale => locale.slug === 'ru'),

        title_en: null,
        body_en: null,
        desc_en: null,

        title_ru: null,
        body_ru: null,
        desc_ru: null,

        title_ar: null,
        body_ar: null,
        desc_ar: null,

        published_at: null,

        image_id: null,
        preview_id: null,
        gallery_id: null,

        related: []
      });
      await this.$store.commit("SET_IMAGE", {
        id: null,
        name: null,
        path: null,
        created_at: null
      });
    }
  },

  methods: {
    async handleSubmit() {
      try {

        let article = this.article;
        let related = [];

        if (!this.empty(article.title_ru) || !this.empty(article.body_ru) || !this.empty(article.desc_ru)) {
          if (!article.locales.find(locale => locale.slug === 'ru')) {
            article.locales.push(this.locales.filter(locale => locale.slug === 'ru')[0])
          }
        } else {
          article.locales = article.locales.filter(locale => locale.slug !== 'ru');
        }

        if (!this.empty(article.title_en) || !this.empty(article.body_en) || !this.empty(article.desc_en)) {
          if (!article.locales.find(locale => locale.slug === 'en')) {
            article.locales.push(this.locales.filter(locale => locale.slug === 'en')[0])
          }
        } else {
          article.locales = article.locales.filter(locale => locale.slug !== 'en');
        }

        if (!this.empty(article.title_ar) || !this.empty(article.body_ar) || !this.empty(article.desc_ar)) {
          if (!article.locales.find(locale => locale.slug === 'ar')) {
            article.locales.push(this.locales.filter(locale => locale.slug === 'ar')[0])
          }
        } else {
          article.locales = article.locales.filter(locale => locale.slug !== 'ar');
        }

        this.selectedSimilarNews.forEach(item => {
          related.push(item.value)
        });

        if (this.selectedGallery) {
          article.gallery_id = this.selectedGallery.value;
        } else {
          article.gallery_id = null;
        }

        article.related = related;

        if (this.isCreating) {
          await this.$store.dispatch("CREATE_ARTICLE", article);
        } else {
          await this.$store.dispatch("UPDATE_ARTICLE", article);
        }

        this.$notify({
          type: "success",
          message: this.isCreating ? 'Новость успешно создана' : ' Новость успешно обновлена',
        });
        if (this.isCreating) {
          await this.$router.push({name: "news.show", params: {id: this.article.id}});
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          type: "danger",
          message: "Уупс, что-то пошло не так!",
        });
      }
    },

    async rollback() {
      await this.$store.dispatch("GET_ARTICLE", {id: this.$route.params.id});
    },

    async deleteArticle() {
      await this.$store.dispatch("DELETE_ARTICLE", {
        id: this.$route.params.id
      }).then(() => {
        this.$notify({type: 'success', message: 'Новость успешно удалена'})
        this.$router.push({name: "news"});
      }).catch(() => {
        this.$notify({type: 'danger', message: 'Новость не удалена'})
      });
    },

    empty(data) {
      if (typeof data === "undefined") {
        return true;
      } else if (data === "0") {
        return true;
      } else if (!data) {
        return true;
      } else if (/\S/.test(data) == "") {
        return true;
      } else {
        return false;
      }
    }
  },
}
</script>
