<template>
  <div>
    <card v-for="person in team" :key="person.id" class="mb-3" shadow type="secondary">
      <template v-slot:header>
        <div class="bg-white border-0">
          <div class="row align-items-center">
            <div class="col-auto text-dark">
              <h3 class="mb-0">{{
                  (person.first_name_ru || person.second_name_ru ? person.first_name_ru + ' ' + person.second_name_ru : (person.first_name_en || person.second_name_en ? person.first_name_en + ' ' + person.second_name_en : (person.first_name_ar || person.second_name_ar ? person.first_name_ar + ' ' + person.second_name_ar : '')))
                }}</h3>
            </div>
            <div class="col text-right">
              <base-button class="text-right" size="sm" type="btn-outline"
                           @click="deletePerson(person)">
                <i class="fas fa-lg fa-trash text-dark"></i>
              </base-button>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:default>
        <tabs default="RU">
          <tab-pane title="RU">
            <div class="col">
              <base-input
                  v-model="person.first_name_ru"
                  input-classes="form-control-alternative"
                  label="Имя"
                  placeholder="Введите имя"
              />
              <base-input
                  v-model="person.second_name_ru"
                  input-classes="form-control-alternative"
                  label="Фамилия"
                  placeholder="Введите фамилию"
              />
              <base-input
                  v-model="person.job_ru"
                  input-classes="form-control-alternative"
                  label="Должность"
                  placeholder="Укажите должность"
              />
              <label class="form-control-label">Описание</label>
              <editor v-model="person.bio_ru" api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                      class="form-control-alternative"/>
            </div>
          </tab-pane>
          <tab-pane title="ENG">
            <div class="col">
              <base-input
                  v-model="person.first_name_en"
                  input-classes="form-control-alternative"
                  label="Имя"
                  placeholder="Введите имя"
              />
              <base-input
                  v-model="person.second_name_en"
                  input-classes="form-control-alternative"
                  label="Фамилия"
                  placeholder="Введите фамилию"
              />
              <base-input
                  v-model="person.job_en"
                  input-classes="form-control-alternative"
                  label="Должность"
                  placeholder="Укажите должность"
              />
              <label class="form-control-label">Описание</label>
              <editor v-model="person.bio_en" api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                      class="form-control-alternative"/>
            </div>
          </tab-pane>
          <tab-pane title="AR">
            <div class="col">
              <base-input
                  v-model="person.first_name_ar"
                  input-classes="form-control-alternative"
                  label="Имя"
                  placeholder="Введите имя"
              />
              <base-input
                  v-model="person.second_name_ar"
                  input-classes="form-control-alternative"
                  label="Фамилия"
                  placeholder="Введите фамилию"
              />
              <base-input
                  v-model="person.job_ar"
                  input-classes="form-control-alternative"
                  label="Должность"
                  placeholder="Укажите должность"
              />
              <label class="form-control-label">Описание</label>
              <editor v-model="person.bio_ar" api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                      class="form-control-alternative"/>
            </div>
          </tab-pane>
        </tabs>
        <div class="ml-1 mt-4 row">
          <div class="col-4">
            <div class="text-left">
              <base-input
                  v-model="person.position"
                  input-classes="form-control-alternative"
                  label="Позиция"
                  placeholder="Укажите позицию"
              />
            </div>
          </div>
          <div class="col-4 text-left">
            <div class="text-center">
              <UploadImage
                  v-model="person.image"
                  textChange="Изменить превью"
                  textUpload="Загрузить превью"
              />
            </div>

          </div>
          <div class="col-4 text-right">
            <div class="text-center">
              <UploadImage
                  v-model="person.preview"
                  textChange="Изменить изображение"
                  textUpload="Загрузить изображение"
              />
            </div>

          </div>
        </div>
      </template>
    </card>
  </div>
</template>

<script>

import Editor from "@tinymce/tinymce-vue";
import UploadImage from "./UploadImage";

export default {
  name: "OurTeam",
  components: {
    UploadImage, Editor
  },
  props: {
    value: {
      required: true
    }
  },
  computed: {
    team: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  data() {
    return {
      currentLang: 'ru',
    }
  },
  methods: {
    deletePerson(person) {
      this.$emit('delete', person);
    }
  }
}
</script>

<style scoped>

</style>