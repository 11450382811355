<template>
  <div class="container-fluid mt--7">
    <div class="order-xl-1 mb-5 mb-xl-0">
      <card shadow type="secondary">
        <template v-slot:header>
          <div class="bg-white border-0">
            <div class="row align-items-center">
              <div class="col-3 text-dark">
                <h3 class="mb-0">{{ title }}</h3>
              </div>
              <div class="col text-right">
                <base-button size="sm" type="btn-outline" @click="createPage"><i
                    class="fas fa-lg fa-plus text-dark"></i></base-button>
              </div>
            </div>
          </div>
        </template>
        <template v-if="tableData && tableData.length > 0" v-slot:default class="card-body">
          <div class="table-responsive">
            <base-table
                :data="tableData"
                class="table align-items-center table-flush"
                tbody-classes="list"
                thead-classes="thead-light"
            >
              <template slot="columns">
                <th></th>
                <th>Язык</th>
                <th>Позиция</th>
                <th>Заголовок</th>
                <th>Дата создания</th>
                <th></th>
              </template>

              <template slot-scope="{ row }">
                <td>
                  #
                </td>
                <td>
                  <span class="name mb-0 text-sm">{{ getLocalesString(row.locales) }}</span>
                </td>
                <td>
                  {{ row.position }}
                </td>
                <th class="budget" scope="row">
                  <div class="media align-items-center">
                    <div class="media-body">
                      <router-link :to="{name: 'oki.pages.show', params: {id: row.id}}" class="name mb-0 text-sm">
                        {{
                          row.title_ru ? row.title_ru : row.title_en ? row.title_en : row.title_ar ? row.title_ar : ''
                        }}
                      </router-link>
                    </div>
                  </div>
                </th>
                <td>
                  {{ dateToYMDHi(new Date(row.published_at)) }}
                </td>
                <td class="text-right">
                  <base-button size="sm" type="btn-outline" @click="editPage(row.id)"><i
                      class="fas fa-lg fa-edit text-dark"></i></base-button>
                  <base-button size="sm" type="btn-outline" @click="deletePage(row.id)"><i
                      class="fas fa-lg fa-trash text-dark"></i></base-button>
                </td>
              </template>
            </base-table>
          </div>
        </template>
      </card>
    </div>
    <ConfirmDialogue ref="confirmDialogue"/>
  </div>
</template>
<script>

import dateToFormatMixin from '../../mixins/date-to-format-mixin';

export default {
  name: 'Pages',
  mixins: [
    dateToFormatMixin
  ],
  data() {
    return {
      tableData: null,
      title: 'Список страниц OKI',
      showModal: false,
    };
  },

  async created() {
    this.$store.watch(
        () => this.$store.getters["PAGES_LIST"],
        (list) => {
          this.tableData = list
        }
    );
    await this.$store.dispatch("GET_PAGES_LIST");
  },

  methods: {
    getLocalesString(localesArray) {

      if (Array.isArray(localesArray) && localesArray.length) {
        let localesString = '';

        let localesCount = localesArray.length;
        let localesInserted = 1;

        localesArray.forEach(locale => {
          localesString = localesString + ' ' + locale.slug;
          if (localesInserted < localesCount) {
            localesString += ' | '
            localesInserted += 1;
          }
        });

        return localesString;
      }

      return "";
    },

    editPage(id) {
      this.$router.push({name: "oki.pages.show", params: {id: id}});
    },
    createPage() {
      this.$router.push({name: "oki.pages.create"});
    },
    deletePage(id) {
      this.$store.dispatch("DELETE_PAGE", {
        id: id
      }).then(() => {
        this.$store.dispatch("GET_PAGES_LIST");
        this.$notify({type: 'success', message: 'Страница успешно удалена'})
      }).catch(() => {
        this.$notify({type: 'danger', message: 'Страница не удалена'})
      });
    }
  },
};
</script>
