<template>
  <div class="container-fluid mt--7">
    <div v-if="newsPage" class="order-xl-1 mb-5">
      <card shadow type="secondary">
        <template v-slot:header>
          <div class="bg-white border-0">
            <div class="row align-items-center">
              <div class="col-3 text-dark">
                <h3 class="mb-0">Настройка страницы</h3>
              </div>
              <div class="col text-right">
                <base-button size="sm" type="btn-outline" @click="saveNewsPage"><i
                    class="fas fa-lg fa-save text-dark"></i></base-button>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:default>
          <h3 class="heading-small text-muted mb-4">Настройка Изображение/Видео</h3>
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col">
                  <UploadVideo v-model="newsPage.video"/>
                </div>
                <div class="col">
                  <UploadVideo
                      v-model="newsPage.mobile_video"
                      textChange="Изменить видео для мобильной версии"
                      textUpload="Загрузить видео для мобильной версии"/>
                </div>
              </div>
              <label class="form-control-label mt-4">Отображать видео</label>
              <div>
                <label class="custom-toggle">
                  <input v-model="newsPage.show_video"
                         type="checkbox">
                  <span class="custom-toggle-slider rounded-circle"></span>
                </label>
              </div>
            </div>
            <div class="col-6 text-center">
              <div class="row">
                <div class="col">
                  <UploadImage
                      v-model="newsPage.image"
                      textChange="Изменить изображение"
                      textUpload="Загрузить изображение"
                  />
                </div>
                <div class="col">
                  <UploadImage
                      v-model="newsPage.mobile_image"
                      textChange="Изменить изображение для мобильной версии"
                      textUpload="Загрузить изображение для мобильной версии"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </card>
    </div>
    <div class="order-xl-1 mb-5 mb-xl-0">
      <card shadow type="secondary">
        <template v-slot:header>
          <div class="bg-white border-0">
            <div class="row align-items-center">
              <div class="col-3 text-dark">
                <h3 class="mb-0">{{ title }}</h3>
              </div>
              <div class="col text-right">
                <base-button size="sm" type="btn-outline" @click="createArticle"><i
                    class="fas fa-lg fa-plus text-dark"></i></base-button>
              </div>
            </div>
          </div>
        </template>
        <template v-if="tableData && tableData.length > 0" v-slot:default class="card-body">
          <div class="table-responsive">
            <base-table
                :data="tableData"
                class="table align-items-center table-flush"
                tbody-classes="list"
                thead-classes="thead-light"
            >
              <template slot="columns">
                <th></th>
                <th>Язык</th>
                <th>Заголовок</th>
                <th>Дата публикации</th>
                <th></th>
              </template>

              <template slot-scope="{ row }">
                <td>
                  #
                </td>
                <td>
                  <span class="name mb-0 text-sm">{{ getLocalesString(row.locales) }}</span>
                </td>
                <th class="budget" scope="row">
                  <div class="media align-items-center">
                    <div class="media-body">
                      <router-link :to="{name: 'news.show', params: {id: row.id}}" class="name mb-0 text-sm">
                        {{
                          (row.title_ru ? row.title_ru : row.title_en ? row.title_en : row.title_ar ? row.title_ar : '').slice(0, 30) + '...'
                        }}
                      </router-link>
                    </div>
                  </div>
                </th>
                <td>
                  {{ dateToYMDHi(new Date(row.published_at)) }}
                </td>
                <td class="text-right">
                  <base-button size="sm" type="btn-outline" @click="editArticle(row.id)"><i
                      class="fas fa-lg fa-edit text-dark"></i></base-button>
                  <base-button size="sm" type="btn-outline" @click="deleteArticle(row.id)"><i
                      class="fas fa-lg fa-trash text-dark"></i></base-button>
                </td>
              </template>
            </base-table>
          </div>
        </template>
      </card>
    </div>
    <ConfirmDialogue ref="confirmDialogue"/>
  </div>
</template>
<script>

import UploadImage from "../../components/UploadImage";
import UploadVideo from "../../components/UploadVideo";
import dateToFormatMixin from '../../mixins/date-to-format-mixin';

export default {
  name: 'News',
  components: {
    UploadImage, UploadVideo
  },
  mixins: [
    dateToFormatMixin
  ],
  data() {
    return {
      tableData: null,
      newsPage: null,
      title: 'Список новостей',
      showModal: false,
    };
  },

  async created() {
    this.$store.watch(
        () => this.$store.getters["NEWS_LIST"],
        (list) => {
          this.tableData = list
        }
    );
    await this.$store.dispatch("GET_NEWS_LIST");

    this.$store.watch(
        () => this.$store.getters["NEWS_PAGE"],
        (page) => {
          this.newsPage = page;
        }
    );
    await this.$store.dispatch("GET_NEWS_PAGE");
  },

  methods: {
    getLocalesString(localesArray) {

      if (Array.isArray(localesArray) && localesArray.length) {
        let localesString = '';

        let localesCount = localesArray.length;
        let localesInserted = 1;

        localesArray.forEach(locale => {
          localesString = localesString + ' ' + locale.slug;
          if (localesInserted < localesCount) {
            localesString += ' | '
            localesInserted += 1;
          }
        });

        return localesString;
      }

      return "";
    },

    async saveNewsPage() {
      try {
        await this.$store.dispatch("UPDATE_NEWS_PAGE", this.newsPage);

        this.$notify({
          type: "info",
          message: "Информация обновлена",
        });
      } catch (error) {

        this.$notify({
          type: "danger",
          message: "Уупс, что-то пошло не так!",
        });
      }
    },

    editArticle(id) {
      this.$router.push({name: "news.show", params: {id: id}});
    },
    createArticle() {
      this.$router.push({name: "news.create"});
    },
    deleteArticle(id) {
      this.$store.dispatch("DELETE_ARTICLE", {
        id: id
      }).then(() => {
        this.$store.dispatch("GET_NEWS_LIST");
        this.$notify({type: 'success', message: 'Новость успешно удалена'})
      }).catch(() => {
        this.$notify({type: 'danger', message: 'Новость не удалена'})
      });
    }
  },
};
</script>
