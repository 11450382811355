<template>
  <div class="container-fluid mt--7">
    <div v-if="customPage && customPage.partners" class="order-xl-1 mb-5 mb-xl-0">
      <card shadow type="secondary">
        <template v-slot:header>
          <div class="bg-white border-0">
            <div class="row align-items-center">
              <div class="col-3 text-dark">
                <h3 class="mb-0">Our projects</h3>
              </div>
              <div class="col-auto text-center">
                <div class="row">
                  <Multiselect
                      v-model="activePartner"
                      :options="customPage.partners"
                      label="description"
                      mode="single"
                      track-by="id"
                  />
                </div>
              </div>
              <div class="col text-right">
                <button class="btn btn-outline" size="sm" @click="handleSubmit">
                  <i class="fas fa-lg fa-save text-dark"></i>
                </button>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:default>
          <h6 class="heading-small text-muted mb-4">Основная информация</h6>
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col">
                  <UploadVideo v-model="customPage.video"/>
                </div>
                <div class="col">
                  <UploadVideo
                      v-model="customPage.mobile_video"
                      textChange="Изменить видео для мобильной версии"
                      textUpload="Загрузить видео для мобильной версии"/>
                </div>
              </div>
              <label class="form-control-label mt-4">Отображать видео</label>
              <div>
                <label class="custom-toggle">
                  <input v-model="customPage.show_video"
                         type="checkbox">
                  <span class="custom-toggle-slider rounded-circle"></span>
                </label>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col text-center">
                  <UploadImage
                      v-model="customPage.image"
                      textChange="Изменить изображение"
                      textUpload="Загрузить изображение"
                  />
                </div>
                <div class="col text-center">
                  <UploadImage
                      v-model="customPage.mobile_image"
                      textChange="Изменить изображение для мобильной версии"
                      textUpload="Загрузить изображение для мобильной версии"
                  />
                </div>
              </div>
            </div>
          </div>
          <h3 class="heading-small text-muted mt-4 mb-4">{{ activePartner.title }} events</h3>
          <div v-if="activePartner.events">
            <card v-for="(event, index) in activePartner.events" :key="index + '_' + activePartner.id" class="mb-3"
                  shadow
                  type="secondary">
              <template v-slot:header>
                <div class="bg-white border-0">
                  <div class="row align-items-center">
                    <div class="col-auto text-dark">
                      <h3 class="mb-0">{{
                          event.title_ru ? event.title_ru : event.title_en ? event.title_en : event.title_ar ? event.title_ar : ''
                        }}</h3>
                    </div>
                    <div class="col text-right">
                      <base-button class="text-right" size="sm" type="btn-outline"
                                   @click="deleteEvent(event)">
                        <i class="fas fa-lg fa-trash text-dark"></i>
                      </base-button>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:default>
                <tabs>
                  <tab-pane v-bind:key="'mainInfoRU'" title="RU">
                    <base-input
                        v-model="activePartner.events[index].title_ru"
                        input-classes="form-control-alternative"
                        label="Заголовок"
                        placeholder="Введите заголовок"
                    />
                    <label class="form-control-label">Описание</label>
                    <editor v-model="activePartner.events[index].description_ru"
                            api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                            class="form-control-alternative"/>
                  </tab-pane>
                  <tab-pane v-bind:key="'mainInfoEN'" title="EN">
                    <base-input
                        v-model="activePartner.events[index].title_en"
                        input-classes="form-control-alternative"
                        label="Заголовок"
                        placeholder="Введите заголовок"
                    />
                    <label class="form-control-label">Описание</label>
                    <editor v-model="activePartner.events[index].description_en"
                            api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                            class="form-control-alternative"/>
                  </tab-pane>
                  <tab-pane v-bind:key="'mainInfoAR'" title="AR">
                    <base-input
                        v-model="activePartner.events[index].title_ar"
                        input-classes="form-control-alternative"
                        label="Заголовок"
                        placeholder="Введите заголовок"
                    />
                    <label class="form-control-label">Описание</label>
                    <editor v-model="activePartner.events[index].description_ar"
                            api-key="jgnyvo7je61ihbuvgwmwc9zbea692fvf0sfofovd2jx0o259"
                            class="form-control-alternative"/>
                  </tab-pane>
                </tabs>
              </template>
            </card>
          </div>
          <div v-else class="text-center">
            <h3 class="heading-small text-muted mb-4">
              Мероприятия отсутствуют (чтобы добавить нажмите на плюсик
              ниже)
            </h3>
          </div>
        </template>
        <template v-slot:footer>
          <base-button size="sm" type="btn-outline" @click="addNewEvent"><i
              class="fas fa-lg fa-plus text-dark"></i></base-button>
        </template>
      </card>
    </div>
  </div>
</template>

<script>

import Multiselect from "vue-multiselect";
import Editor from "@tinymce/tinymce-vue";
import UploadImage from "../../components/UploadImage";
import UploadVideo from "../../components/UploadVideo";

export default {
  name: "form",
  components: {
    Multiselect, Editor, UploadImage, UploadVideo
  },

  data() {
    return {
      locales: null,
      customPage: null,
      activePartner: null,
    }
  },

  async created() {
    this.$store.watch(
        () => this.$store.getters["LOCALES"],
        (list) => {
          this.locales = list;
        }
    );
    await this.$store.dispatch("GET_LOCALES_LIST");

    this.$store.watch(
        () => this.$store.getters["OUR_PROJECTS_PAGE"],
        (page) => {

          page.partners.forEach(partner => {
            partner.events.forEach(event => {
              event.locales = [];
              if ('title_en' in event || 'description_en' in event) {
                event.locales.push(this.locales.find(locale => locale.slug === 'en'));
              }
              if ('title_ru' in event || 'description_ru' in event) {
                event.locales.push(this.locales.find(locale => locale.slug === 'ar'));
              }
              if ('title_ar' in event || 'description_ar' in event) {
                event.locales.push(this.locales.find(locale => locale.slug === 'ar'));
              }
            })
          });

          this.customPage = page;
          if (!this.activePartner && this.customPage.partners && this.customPage.partners.length > 0) {
            this.activePartner = this.customPage.partners[0];
          }
        }
    );

    await this.$store.dispatch("GET_OUR_PROJECTS_PAGE");
  },

  methods: {
    async handleSubmit() {
      try {
        this.customPage.partners.forEach(partner => {
          partner.events.forEach(event => {
            delete event.locales;
          });
        });
        await this.$store.dispatch("UPDATE_OUR_PROJECTS_PAGE", this.customPage);

        this.$notify({
          type: "info",
          message: "Информация обновлена",
        });
      } catch (error) {

        this.$notify({
          type: "danger",
          message: "Уупс, что-то пошло не так!",
        });
      }
    },
    updateEventLocale(selectedOption) {
      this.activePartner.events.forEach(event => {
        event['title_' + selectedOption.slug] = null;
        event['description_' + selectedOption.slug] = null;
      });
    },
    addNewEvent() {
      let event = {
        title: null,
        description: null
      };

      if (this.activePartner.events === null) {
        this.activePartner.events = [event];
      } else {
        this.activePartner.events.push(event);
      }
    },
    deleteEvent(event) {
      this.activePartner.events = this.activePartner.events.filter(item => item !== event);
    }
  }
}
</script>