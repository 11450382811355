<template>
  <div class="container-fluid mt--7">
    <div class="order-xl-1 mb-5 mb-xl-0">
      <card v-if="customPage !== null" shadow type="secondary">
        <template v-slot:header>
          <div class="bg-white border-0">
            <div class="row align-items-center">
              <div class="col-3 text-dark">
                <h3 class="mb-0">ABOUT QRCC GROUP</h3>
              </div>
              <div class="col text-right">
                <button class="btn btn-outline" size="sm" @click="handleSubmit">
                  <i class="fas fa-lg fa-save text-dark"></i>
                </button>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:default>
          <h6 class="heading-small text-muted mb-4">Основная информация</h6>
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col">
                  <UploadVideo v-model="customPage.video"/>
                </div>
                <div class="col">
                  <UploadVideo
                      v-model="customPage.mobile_video"
                      textChange="Изменить видео для мобильной версии"
                      textUpload="Загрузить видео для мобильной версии"/>
                </div>
              </div>
              <label class="form-control-label mt-4">Отображать видео</label>
              <div>
                <label class="custom-toggle">
                  <input v-model="customPage.show_video"
                         type="checkbox">
                  <span class="custom-toggle-slider rounded-circle"></span>
                </label>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col text-center">
                  <UploadImage
                      v-model="customPage.image"
                      textChange="Изменить изображение"
                      textUpload="Загрузить изображение"
                  />
                </div>
                <div class="col text-center">
                  <UploadImage
                      v-model="customPage.mobile_image"
                      textChange="Изменить изображение для мобильной версии"
                      textUpload="Загрузить изображение для мобильной версии"
                  />
                </div>
              </div>
            </div>
          </div>
          <h6 class="heading-small text-muted mt-4 mb-4">{{
              customPage.persons.length > 0 ? 'OUR TEAM' : 'Похоже, что в команде никого( Нажмите на плюсик ниже чтобы добавить'
            }}</h6>
          <OurTeam v-model="customPage.persons" @delete="deletePerson"/>
        </template>
        <template v-slot:footer>
          <button class="btn btn-outline" size="sm" @click="addPerson">
            <i class="fas fa-lg fa-plus text-dark"></i>
          </button>
        </template>
      </card>
    </div>
  </div>
</template>

<script>

import UploadImage from "../../components/UploadImage";
import OurTeam from "../../components/OurTeam";
import UploadVideo from "../../components/UploadVideo";


export default {
  name: "form",
  components: {
    UploadVideo,
    UploadImage, OurTeam
  },
  watch: {
    locales(languages) {
      const ru = languages.find(element => element.value === 'ru');
      const en = languages.find(element => element.value === 'en');
      const ar = languages.find(element => element.value === 'ar');

      if (!en) {
        this.customPage.first_name_en = null;
        this.customPage.second_name_en = null;
        this.customPage.bio_en = null;
        this.customPage.job_en = null;
      }

      if (!ru) {
        this.customPage.first_name_ru = null;
        this.customPage.second_name_ru = null;
        this.customPage.bio_ru = null;
        this.customPage.job_ru = null;
      }

      if (!ar) {
        this.customPage.first_name_ar = null;
        this.customPage.second_name_ar = null;
        this.customPage.bio_ar = null;
        this.customPage.job_ar = null;
      }
    }
  },
  data() {
    return {
      customPage: null
    }
  },
  async created() {
    this.$store.watch(
        () => this.$store.getters["OUR_TEAM"],
        (page) => {
          this.customPage = page;
        }
    );
    await this.$store.dispatch("GET_OUR_TEAM");
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$store.dispatch("UPDATE_OUR_TEAM", this.customPage)
        this.$notify({
          type: "info",
          message: "Информация обновлена",
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Уупс, что-то пошло не так!",
        });
      }
    },
    addPerson() {
      let person = {
        position: null,
        first_name_ru: null,
        first_name_en: null,
        first_name_ar: null,
        second_name_ru: null,
        second_name_en: null,
        second_name_ar: null,
        bio_ru: null,
        bio_en: null,
        bio_ar: null,
        job_ru: null,
        job_en: null,
        job_ar: null,
        locales: this.locales,
        image: null,
        preview: null,
      };

      if (this.customPage && this.customPage.persons && this.customPage.persons.length > 0) {
        this.customPage.persons.push(person);
      } else {
        this.customPage.persons = [];
        this.customPage.persons.push(person);
      }
    },
    deletePerson(person) {
      this.customPage.persons = this.customPage.persons.filter(function (item) {
        return item !== person;
      })
    }
  }
}
</script>