<template>
  <div class="container-fluid mt--7">
    <div v-if="mainPage" class="order-xl-1 mb-5 mb-xl-0">
      <card shadow type="secondary">
        <template v-slot:header>
          <div class="bg-white border-0">
            <div class="row align-items-center">
              <div class="col-6 text-dark">
                <h3 class="mb-0">Настройки главной страницы</h3>
              </div>
              <div class="col text-right">
                <base-button size="sm" type="btn-outline" @click="saveMainPage"><i
                    class="fas fa-lg fa-save text-dark"></i></base-button>
              </div>
            </div>
          </div>
        </template>

        <template v-slot:default>
          <h3 class="heading-small text-muted mb-4">Настройка Изображение/Видео</h3>
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col">
                  <UploadVideo v-model="mainPage.video"/>
                </div>
                <div class="col">
                  <UploadVideo
                      v-model="mainPage.mobile_video"
                      textChange="Изменить видео для мобильной версии"
                      textUpload="Загрузить видео для мобильной версии"/>
                </div>
              </div>
              <label class="form-control-label mt-4">Отображать видео</label>
              <div>
                <label class="custom-toggle">
                  <input v-model="mainPage.show_video"
                         type="checkbox">
                  <span class="custom-toggle-slider rounded-circle"></span>
                </label>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col text-center">
                  <UploadImage
                      v-model="mainPage.image"
                      textChange="Изменить изображение"
                      textUpload="Загрузить изображение"
                  />
                </div>
                <div class="col text-center">
                  <UploadImage
                      v-model="mainPage.mobile_image"
                      textChange="Изменить изображение для мобильной версии"
                      textUpload="Загрузить изображение для мобильной версии"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <!--<label class="form-control-label">Выберите главную новость</label>
              <div v-if="newsList && newsList.length > 0" class="form-group">
                <Multiselect
                    v-model="selectedMainArticle"
                    :clear-on-select="false"
                    :close-on-select="false"
                    :options="newsList"
                    label="label"
                    mode="single"
                    track-by="value"
                />
              </div>
              -->
              <template v-if="galleriesList && galleriesList.length > 0">
                <label class="form-control-label">Выберите галерею</label>
                <Multiselect
                    v-model="selectedGallery"
                    :options="galleriesList"
                    label="label"
                    mode="single"
                    track-by="value"
                />
              </template>
            </div>
          </div>
        </template>
      </card>
    </div>
  </div>
</template>

<script>

import Multiselect from "vue-multiselect";
import UploadImage from "../components/UploadImage";
import UploadVideo from "../components/UploadVideo";

export default {
  name: 'News',
  components: {
    UploadImage, UploadVideo, Multiselect
  },
  data() {
    return {
      tableData: null,
      selectedMainArticle: null,
      selectedGallery: null,
      galleriesList: [],
      newsList: [],
      mainPage: null,
      title: 'Список новостей',
      showModal: false,
    };
  },

  async created() {
    this.$store.watch(
        () => this.$store.getters["MAIN_ARTICLE"],
        (item) => {
          this.selectedMainArticle = item[0];
        }
    );
    await this.$store.dispatch("GET_MAIN_ARTICLE");

    this.$store.watch(
        () => this.$store.getters["RELATED_NEWS_LIST"],
        (list) => {
          this.newsList = list
        }
    );
    await this.$store.dispatch("GET_RELATED_NEWS_LIST");

    this.$store.watch(
        () => this.$store.getters["GALLERIES_LIST"],
        (list) => {

          let processedList = [];

          list.forEach(item => {
            processedList.push({
              value: item.id,
              label: item.name
            });
          });

          this.galleriesList = processedList;
        }
    );
    await this.$store.dispatch("GET_GALLERIES_LIST");

    this.$store.watch(
        () => this.$store.getters["MAIN_PAGE"],
        (page) => {
          this.mainPage = page;
          if (this.galleriesList.length > 0) {
            this.galleriesList.forEach(gallery => {
              if (gallery.value === this.mainPage.gallery_id) {
                this.selectedGallery = gallery;
              }
            });
          }
        }
    );
    await this.$store.dispatch("GET_MAIN_PAGE");
  },

  methods: {
    async saveMainPage() {
      try {
        this.mainPage.gallery_id = this.selectedGallery ? this.selectedGallery.value : null;
        await this.$store.dispatch("UPDATE_MAIN_PAGE", this.mainPage);
        await this.$store.dispatch('SET_MAIN_ARTICLE', {
          id: this.selectedMainArticle.value
        });

        this.$notify({
          type: "info",
          message: "Информация обновлена",
        });
      } catch (error) {

        this.$notify({
          type: "danger",
          message: "Уупс, что-то пошло не так!",
        });
      }
    }
  },
};
</script>
